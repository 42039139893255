import axios from 'axios';
const baseURL = 'https://easy-jackets2.onrender.com/api/v1'
// const baseURL= 'http://localhost:8080/api/v1'
// Create an Axios instance
const axiosInstance = axios.create({
    baseURL,
});

// Add a request interceptor to set the Authorization header and content type
axiosInstance.interceptors.request.use(
    config => {
        // Set the content type to JSON
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        // Handle request errors
        return Promise.reject(error);
    }
);

export default axiosInstance;
