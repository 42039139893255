import React from 'react'

const Simple = ({ colorPicker, base, lining, flip }) => (
  <>
    { flip ? (
      <path d="M131.8,26c-4-11.45-9.88-18.12-24.24-21.62C90.26.12,48.69.09,31.39,4.32,17,7.82,11.17,14.49,7.14,25.94c-3.41,9.73-6.2,24-6.2,24s38.53,5.52,70.67,5.52,66.59-5.52,66.59-5.52A231.27,231.27,0,0,0,131.8,26Z" transform="translate(187, 27)" fill={base} stroke="#000" strokeMiterlimit="10" className="cjd-color-hover" onClick={ () => colorPicker('base') } />
    ) : (
      <g transform="translate(187, 25)">
        <path d="M73.23,79a9.74,9.74,0,0,1-.11-1.84c.44-14.12,30.31-37.53,43.36-47.35,4.1-3.12,14.89-8.95,4.32-16C102,2.94,39.8,3.05,21,13.83c-10.57,7,.32,12.94,4.31,16C38.39,39.72,68.06,63,68.7,77A11.54,11.54,0,0,1,68.59,79Z" transform="translate(-0.35 -0.86)" fill={lining} className="cjd-color-hover" onClick={ () => colorPicker('lining') } />
        <g className="cjd-color-hover" onClick={ () => colorPicker('base') }>
          <path d="M25.34,29.79c4.43,3.35,10.9,8.42,17.48,14H98.9c6.58-5.61,13-10.68,17.48-14,4.09-3.12,14.88-8.95,4.31-16-18.77-10.89-81-10.78-99.77,0C10.46,21,21.24,26.77,25.34,29.79Z" transform="translate(-0.35 -0.86)" fill={base} />
          <path d="M21,13.86c-10.53,7,.28,12.9,4.34,16C38.91,40.17,70.87,65.23,68.53,79c0,0-24.24.54-42.33-7.31S2.28,55.21.88,52.73c0,0,3-16.19,6.5-26.1C11.48,15,17.45,8.17,32.07,4.61,49.69.3,92,.27,109.66,4.57c14.61,3.57,20.58,10.36,24.68,22,3.48,9.91,6.54,26.13,6.54,26.13-1.4,2.47-7.26,11.1-25.36,19S73.22,79,73.22,79c-2.34-13.8,29.6-38.86,43.21-49.19,4.06-3.08,14.87-8.94,4.34-16C102,3,39.72,3,21,13.86Z" transform="translate(-0.35 -0.86)" fill={base} stroke="#000" strokeMiterlimit="10"/>
        </g>
      </g>
    )}
  </>
)

export default Simple