import React from 'react'

const Band = ({ colorPicker, band, lining, flip }) => (
  <>
    { flip ? (
      <g stroke={band == "#000000" ? "#ffffff" : "#1d1d1b"} strokeMiterlimit="10" transform="translate(180, 34)">
        <path id="line" d="M1.22,44.62,8.06,17.69c1.35-5.16,15-17,69.45-17s68.1,12,69.45,17.05l6.84,26.93s-37.58-4.26-76.29-4.26S1.22,44.62,1.22,44.62Z" transform="translate(-0.56 -0.14)" fill={band} className="cjd-color-hover" onClick={ () => colorPicker('band') } />
      </g>
    ) : (
      <g data-name="collar ban front" transform="translate(180, 34)">
        <g fill={band} stroke={band == "#000000" ? "#ffffff" : "#1d1d1b"} strokeMiterlimit="10" className="cjd-color-hover" onClick={ () => colorPicker('band') }>
          <path d="M79.47,68.58H76.22c-7.62-.11-37-2.92-51.12-8.41C7.83,53.55,2.34,46.27,1,44.25A83.1,83.1,0,0,0,7.16,31.36c1.91-4.94,4.37-17.83,8-20.86S40.35.64,76.45.64H77.9c36.21,0,57.74,6.84,61.33,9.86s6.05,16,8,20.86a97.94,97.94,0,0,0,6.16,12.89c-1.34,2.13-6.95,9.3-24.1,15.92-14.13,5.38-43.5,8.3-51.12,8.41H74.88" transform="translate(-0.4 -0.14)" />
          <path d="M7.27,31.24c1.91-4.93,4.38-17.82,8-20.85C16.58,9.27,18.15,33,68.82,33c1.12,0,7.51.11,7.51,4.37V68.58c-7.62-.11-37-2.92-51.12-8.41C8,53.55,2.45,46.27,1.11,44.25A88.45,88.45,0,0,0,7.27,31.24Z" transform="translate(-0.4 -0.14)" />
          <path d="M147.41,31.24c-1.9-4.93-4.37-17.82-8-20.85C138.11,9.27,136.54,33,85.86,33c-1.12,0-7.51.11-7.51,4.37V68.58c7.62-.11,37-2.92,51.12-8.41,17.27-6.62,22.76-13.9,24.11-15.92A128.22,128.22,0,0,1,147.41,31.24Z" transform="translate(-0.4 -0.14)" />
        </g>
        <path d="M77.79,25.75h-.67c-16.82,0-32.63.11-45.07.79C39.9,30.24,51.44,33,68.93,33c1.13,0,7.52.11,7.52,4.37V68.47h.33c.34,0,.9-.12,1.8-.12V37.41C78.58,33.15,85,33,86.09,33c17.49,0,29-2.8,36.88-6.5C110.41,26,94.5,25.75,77.79,25.75Z" transform="translate(-0.4 -0.14)" fill={lining} className="cjd-color-hover" onClick={ () => colorPicker('lining') } />
      </g>
    )}
  </>
)

export default Band