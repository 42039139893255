import React, { useState } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';

import { designColor, chooseName } from '../../store/actions';
import { BODY_COLORS } from '../../utils';

import SelectBox from '../selectBox';
// import { ReactComponent as Seniors } from '../../assets/images/badges/symbol.svg';
//import './editables.scss';
import '../../css/components/Modal/editables.scss';

const COUNTRIES = [
  'australia',
  'canada',
  'colombia',
  'denmark',
  'france',
  'germany',
  'india',
  'ireland',
  'italy',
  'japan',
  'korea',
  'netherlands',
  'newzeland',
  'norway',
  'pakistan',
  'russia',
  'saudia',
  'singapore',
  'sweden',
  'turkey',
  'uae',
  'uk',
  'usa',
  'venezuela',
];

const Symbol = ({ globals, part, colors, updateColor, updateName, designs }) => {
  const [cPanel, setCpanel] = useState(false);
  const [colPart, setColPart] = useState('fill');
  const [flag, setFlag] = useState(designs[part]?.symbol?.flag || '');
  const [type, setType] = useState(designs[part]?.symbol?.type || 'Badges');
  const [view, setView] = useState(designs[part]?.symbol?.type || 'Show All');

  const viewCbox = (val) => {
    val === colPart && cPanel ? setCpanel(false) : setCpanel(true);
    setColPart(val);
  };

  const flagFn = (val, type) => {
    setFlag(val);
    setType(type);
    updateName('flag', val, part);
    updateName('type', type, part);
  };

  const onSelect = (option) => {
    setView(option.value);
  };

  // const chooseBadge = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   let svg = e.target.innerHTML;

  //   setType('Badges');
  //   setFlag(svg);
  //   updateName('path', svg, part);
  //   updateName('type', 'Badges', part);
  // };

  const mascots = (type) => {
    let alphabets = [];

    for (let i = 1; i <= 42; i++) {
      alphabets.push(
        <span key={i} className={`cjd-letter-wrapper`} onClick={() => flagFn(i, 'Mascots')}>
          <img src={require(`../../assets/images/mascots/${i}.svg`)} alt={i} />
        </span>
      );
    }

    return alphabets;
  };

  return (
    <div className='cjd-modal-form-wrapper'>
      <div className='cjd-row'>
        <div className='cjd-modal-half'>
          <div className='cjd-form-group'>
            <Dropdown
              options={['Show All', 'Badges', 'Flags', 'Mascots']}
              onChange={onSelect}
              value={view}
              placeholder='Select an option'
            />
          </div>

          <div className='cjd-alphabets-wrapper' style={{ height: '382px' }}>
            {(view === 'Show All' || view === 'Flags') && (
              <div className='cjd-font-option'>
                {COUNTRIES.map((flag, i) => {
                  return (
                    <span
                      key={i}
                      className={`cjd-letter-wrapper`}
                      onClick={() => flagFn(flag, 'Flags')}
                    >
                      <img src={require(`../../assets/images/flags/${flag}.svg`)} alt={i} />
                    </span>
                  );
                })}
              </div>
            )}

            {(view === 'Show All' || view === 'Mascots') && (
              <div className='cjd-font-option'> {mascots()} </div>
            )}
          </div>
        </div>

        <div className='cjd-modal-half'>
          <div
            className='cjd-mock-preview'
            style={{
              background:
                part === 'Right Sleeve' ||
                part === 'Left Sleeve' ||
                part === 'Right Sleeve End' ||
                part === 'Left Sleeve End'
                  ? colors.sleeves
                  : colors.body,
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='100%'
              height='100%'
              viewBox='0 0 324 200'
            >
              <g>
                {flag && (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='100%'
                    height='100%'
                    viewBox='0 0 176 102'
                  >
                    {type === 'Flags' && (
                      <>
                        <rect
                          x='0'
                          y='0'
                          width='176'
                          height='102'
                          fill='none'
                          strokeWidth='10px'
                          stroke={designs.fill}
                        />
                        <image
                          width='176'
                          height='102'
                          xlinkHref={require(`../../assets/images/flags/${flag}.svg`)}
                        />
                      </>
                    )}

                    {type === 'Mascots' && (
                      <image
                        width='176'
                        height='102'
                        xlinkHref={require(`../../assets/images/mascots/${flag}.svg`)}
                      />
                    )}

                    {type === 'Badges' && (
                      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 190 190'>
                        <g id='Layer_1-2' data-name='Layer 1'>
                          <circle cx='95' cy='95' r='95' fill={designs.border} />
                          <g>
                            <path
                              d='M34.25,43.91a2.41,2.41,0,0,1,.82-.24,2.47,2.47,0,0,1,.85.09,2.31,2.31,0,0,1,.78.43l.43.36a.3.3,0,0,1,.1.38l-.59,1.61c-.06.15-.16.17-.28.07l-.93-.78a.43.43,0,0,0-.31-.1.41.41,0,0,0-.29.15l-.09.11a.45.45,0,0,0-.1.31.4.4,0,0,0,.15.29l6.14,5.16a.44.44,0,0,0,.31.09.4.4,0,0,0,.29-.15l.09-.1a.42.42,0,0,0,.1-.32.39.39,0,0,0-.15-.28l-2.42-2-.31.38L37.29,48l1.49-1.78a.22.22,0,0,1,.35,0L43,49.45a2.34,2.34,0,0,1,.55.7,2.19,2.19,0,0,1,.23.82,2,2,0,0,1-.09.85,2,2,0,0,1-.42.78l-.43.52a2.23,2.23,0,0,1-1.52.78,2.06,2.06,0,0,1-.85-.09,2,2,0,0,1-.78-.42l-6.28-5.27a2.05,2.05,0,0,1-.55-.69,2.25,2.25,0,0,1-.23-.82,2,2,0,0,1,.09-.85,2,2,0,0,1,.42-.78l.43-.52A2.06,2.06,0,0,1,34.25,43.91Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M51.67,43.69c.14.1.15.2,0,.31l-1.2,1.07a.37.37,0,0,1-.2.09.33.33,0,0,1-.22,0l-3.86-2.36,3.06,3.41L49.16,46a.22.22,0,0,1,0,.29l-1.18,1.05a.22.22,0,0,1-.35,0l-8-8.87a.22.22,0,0,1,0-.35l2-1.78a2.4,2.4,0,0,1,.77-.45,2.36,2.36,0,0,1,1.68.09,2.28,2.28,0,0,1,.71.53l1.93,2.15a2.22,2.22,0,0,1,.54,1.11A2.41,2.41,0,0,1,47.13,41Zm-7-2.7.42-.38a.38.38,0,0,0,.14-.29A.4.4,0,0,0,45.1,40l-1.81-2a.37.37,0,0,0-.29-.14.4.4,0,0,0-.31.11l-.42.38Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M59.39,38.2c.11.13.1.24,0,.33l-1.34.9a.26.26,0,0,1-.36-.06l-1.19-1.51-.91.61,1,1.67a.25.25,0,0,1-.08.35l-1.34.9c-.14.09-.25.07-.32-.09L49.23,30.67a.27.27,0,0,1,.1-.37L51.27,29a.26.26,0,0,1,.38.05Zm-4.15-1.91-3-3.81-.12.08,2.39,4.18Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M60.48,23.85a2.43,2.43,0,0,1,.86-.22,2.2,2.2,0,0,1,.85.13,2.14,2.14,0,0,1,.74.43,2.34,2.34,0,0,1,.54.7l3.45,7.16a2.15,2.15,0,0,1,.22.86,2.23,2.23,0,0,1-.55,1.58,2.1,2.1,0,0,1-.71.54l-2.39,1.16a.23.23,0,0,1-.34-.12L58,25.34a.22.22,0,0,1,.12-.33Zm1.16,2a.42.42,0,0,0-.25-.22.4.4,0,0,0-.32,0l-.51.24,3.74,7.77.51-.25A.4.4,0,0,0,65,33.2a.42.42,0,0,0,0-.33Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M73.16,19.3l3,9.58a2.26,2.26,0,0,1,.09.89,2.35,2.35,0,0,1-.24.82,2.41,2.41,0,0,1-.53.67,2.16,2.16,0,0,1-.77.43l-.65.2a2,2,0,0,1-.88.1,2.24,2.24,0,0,1-1.49-.77,2.06,2.06,0,0,1-.43-.78l-3-9.58a.22.22,0,0,1,.16-.31l1.51-.48a.22.22,0,0,1,.31.16l3,9.5a.41.41,0,0,0,.53.27l.14,0a.43.43,0,0,0,.25-.21.4.4,0,0,0,0-.32l-3-9.5a.23.23,0,0,1,.17-.32l1.5-.47A.22.22,0,0,1,73.16,19.3Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M86.31,28.74c0,.16,0,.25-.17.28l-1.59.27a.26.26,0,0,1-.31-.2l-.46-1.87-1.08.18.18,1.92a.24.24,0,0,1-.22.28l-1.59.27c-.17,0-.25,0-.25-.21l-.68-12a.27.27,0,0,1,.24-.29L82.69,17a.26.26,0,0,1,.32.2Zm-3-3.45L82.18,20.6l-.14,0,.47,4.79Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M94.37,16.29l.06,1.58a.23.23,0,0,1-.24.26l-1.26.05.39,10.08a.22.22,0,0,1-.23.26l-1.58.06a.23.23,0,0,1-.17-.07.2.2,0,0,1-.09-.17l-.39-10.08-1.24,0a.23.23,0,0,1-.26-.24l-.06-1.58c0-.17.07-.25.24-.26l4.57-.18A.23.23,0,0,1,94.37,16.29Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M103.72,27.17l-.14,1.57a.23.23,0,0,1-.27.23l-3.84-.36a.21.21,0,0,1-.22-.27l1.1-11.86c0-.17.1-.24.27-.23l3.83.36a.21.21,0,0,1,.22.27l-.14,1.57a.22.22,0,0,1-.27.22l-2-.18L102,21l1.75.16a.22.22,0,0,1,.22.27L103.84,23a.23.23,0,0,1-.27.23l-1.75-.17-.34,3.62,2,.19A.22.22,0,0,1,103.72,27.17Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M120.83,20.37a2.41,2.41,0,0,1,.53.67,2.34,2.34,0,0,1,.24.83,2.21,2.21,0,0,1-.1.88l-.43,1.35a.31.31,0,0,1-.32.23l-1.61-.26c-.16,0-.22-.08-.17-.24l.53-1.63a.38.38,0,0,0,0-.33.41.41,0,0,0-.25-.21l-.13,0a.42.42,0,0,0-.33,0,.47.47,0,0,0-.21.25l-2.45,7.63a.42.42,0,0,0,0,.33.47.47,0,0,0,.25.21l.14,0a.43.43,0,0,0,.53-.27l.76-2.37c0-.15.15-.19.28-.09l1.47.69a.31.31,0,0,1,.12.38L119,30.55a2,2,0,0,1-.44.77,2.24,2.24,0,0,1-1.49.77,2.39,2.39,0,0,1-.88-.1l-.65-.21a2.16,2.16,0,0,1-.77-.43,2.32,2.32,0,0,1-.53-.68,2.23,2.23,0,0,1-.24-.82,2.41,2.41,0,0,1,.1-.88l2.51-7.8a2.18,2.18,0,0,1,.44-.77,2.41,2.41,0,0,1,.67-.53,2.34,2.34,0,0,1,.83-.24,2.24,2.24,0,0,1,.88.1l.64.21A2.16,2.16,0,0,1,120.83,20.37Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M127.43,34.59,126.74,36a.21.21,0,0,1-.33.11l-2.15-1,0,0h0l-1.42-.68a.22.22,0,0,1-.12-.33l5.16-10.74a.22.22,0,0,1,.33-.12l1.43.69a.22.22,0,0,1,.11.33l-4.37,9.09,2,1A.21.21,0,0,1,127.43,34.59Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M134.87,41c-.08.16-.18.19-.32.09l-1.35-.89a.25.25,0,0,1-.08-.35l.94-1.68-.92-.6L132,39.12a.25.25,0,0,1-.35.06l-1.35-.89c-.14-.09-.15-.2,0-.33l7.68-9.21a.26.26,0,0,1,.37-.05L140.22,30a.26.26,0,0,1,.1.37Zm.16-4.56,2.37-4.2-.12-.07L134.35,36Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M148.93,36.92a2.28,2.28,0,0,1,.22.83,2.49,2.49,0,0,1-.11.85,2.2,2.2,0,0,1-.44.77l-.45.5a.29.29,0,0,1-.38.09l-1.49-.75c-.15-.06-.17-.16-.06-.28l.76-.87a.41.41,0,0,0,.11-.3.44.44,0,0,0-.15-.3l-.1-.09a.44.44,0,0,0-.31-.11.4.4,0,0,0-.29.15l-1.07,1.21a1.14,1.14,0,0,0-.17.3.88.88,0,0,0,0,.34l.6,2.84a1.2,1.2,0,0,1-.06.62,3.54,3.54,0,0,1-.3.51l-2,2.29a2.13,2.13,0,0,1-.71.53,2.23,2.23,0,0,1-.83.22,2.29,2.29,0,0,1-.85-.11,2.16,2.16,0,0,1-.76-.44l-.51-.45a2.16,2.16,0,0,1-.54-.7,2.48,2.48,0,0,1-.21-.83,2.08,2.08,0,0,1,.11-.85,2.06,2.06,0,0,1,.44-.77l1.12-1.28a.31.31,0,0,1,.39-.08l1.49.74c.15.07.16.16,0,.29l-1.44,1.64a.37.37,0,0,0-.1.3.39.39,0,0,0,.14.3l.11.09a.41.41,0,0,0,.6,0l1.54-1.76a1.54,1.54,0,0,0,.22-.35.68.68,0,0,0,.05-.38l-.56-2.83a1.52,1.52,0,0,1,.05-.62,1.45,1.45,0,0,1,.34-.57l1.41-1.6a2.1,2.1,0,0,1,.7-.53,2.28,2.28,0,0,1,.83-.22,2.24,2.24,0,0,1,.85.11,2.2,2.2,0,0,1,.77.44l.51.45A2.28,2.28,0,0,1,148.93,36.92Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M156.57,44.86a2.26,2.26,0,0,1,.1.85,2.5,2.5,0,0,1-.22.83,2.06,2.06,0,0,1-.55.7l-.51.44a.29.29,0,0,1-.39,0l-1.38-.94c-.13-.08-.14-.18,0-.29l.87-.75a.43.43,0,0,0,.15-.29.4.4,0,0,0-.11-.31l-.09-.11a.41.41,0,0,0-.29-.15.44.44,0,0,0-.31.11L152.61,46a1.57,1.57,0,0,0-.21.28.76.76,0,0,0-.09.33l.21,2.89a1.1,1.1,0,0,1-.15.61,2.77,2.77,0,0,1-.36.46l-2.3,2a2.27,2.27,0,0,1-1.63.53,2.26,2.26,0,0,1-1.53-.76l-.44-.51a2.18,2.18,0,0,1-.43-.77,2.28,2.28,0,0,1-.1-.85,2.23,2.23,0,0,1,.22-.83,2.16,2.16,0,0,1,.54-.7l1.29-1.12a.3.3,0,0,1,.39,0l1.38.94c.14.09.14.18,0,.29l-1.65,1.43a.41.41,0,0,0-.15.29.39.39,0,0,0,.1.31l.09.11a.47.47,0,0,0,.3.14.4.4,0,0,0,.31-.1l1.77-1.53a2.29,2.29,0,0,0,.26-.32.66.66,0,0,0,.1-.37l-.17-2.88a1.72,1.72,0,0,1,.13-.61,1.57,1.57,0,0,1,.42-.52l1.61-1.39a2,2,0,0,1,.77-.43,2.29,2.29,0,0,1,.85-.11A2.24,2.24,0,0,1,155,43a2.16,2.16,0,0,1,.7.54l.44.51A2.06,2.06,0,0,1,156.57,44.86Z'
                              fill={designs.stroke}
                            />
                          </g>

                          <g>
                            <path
                              d='M50.07,144.24a2.24,2.24,0,0,1,.13.84,2.29,2.29,0,0,1-.2.84,2.25,2.25,0,0,1-.51.72l-1.05,1a.31.31,0,0,1-.39.05l-1.27-1c-.14-.08-.15-.18,0-.29L48,145.17a.38.38,0,0,0,.14-.29.4.4,0,0,0-.11-.31l-.1-.1a.38.38,0,0,0-.29-.14.44.44,0,0,0-.31.11l-5.89,5.44a.42.42,0,0,0-.13.3.4.4,0,0,0,.11.31l.1.1a.42.42,0,0,0,.29.14.45.45,0,0,0,.31-.12L44,148.93c.12-.11.22-.1.29,0l.94,1.33a.3.3,0,0,1-.08.38l-1.63,1.51a2.13,2.13,0,0,1-.76.46,2.46,2.46,0,0,1-.84.13,2.29,2.29,0,0,1-.84-.2,2.14,2.14,0,0,1-.72-.52l-.45-.49a2.07,2.07,0,0,1-.46-.76,2.29,2.29,0,0,1,.06-1.68,2.14,2.14,0,0,1,.52-.72l6-5.56a2.21,2.21,0,0,1,.76-.46,2.29,2.29,0,0,1,.85-.13,2.3,2.3,0,0,1,1.55.71l.46.5A2.13,2.13,0,0,1,50.07,144.24Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M51.23,159.15l-1,1.24a.21.21,0,0,1-.34.05L48,159l0,0h0l-1.24-1a.21.21,0,0,1,0-.34L54,148.21a.22.22,0,0,1,.35,0l1.25,1a.21.21,0,0,1,0,.34l-6.2,8,1.74,1.35A.22.22,0,0,1,51.23,159.15Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M59.78,166.15c-.06.15-.16.19-.3.11l-1.42-.78a.24.24,0,0,1-.11-.34l.8-1.75-1-.53-1,1.61a.26.26,0,0,1-.36.09L55,163.78c-.15-.08-.17-.18-.07-.32l6.91-9.8a.26.26,0,0,1,.37-.08l2.05,1.13a.27.27,0,0,1,.13.36Zm-.2-4.57,2-4.37-.13-.07-2.61,4.05Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M72.6,158.4a2.25,2.25,0,0,1,.5.7,2.15,2.15,0,0,1,.2.83,2.06,2.06,0,0,1-.14.88l-.23.63a.31.31,0,0,1-.33.22l-1.66-.17c-.16,0-.21-.09-.15-.25l.4-1.08a.4.4,0,0,0,0-.32.4.4,0,0,0-.24-.22l-.13-.05a.42.42,0,0,0-.33,0,.4.4,0,0,0-.22.24l-.56,1.51a1.16,1.16,0,0,0-.06.34.75.75,0,0,0,.09.33l1.57,2.44a1.15,1.15,0,0,1,.16.6,3.39,3.39,0,0,1-.09.59l-1.07,2.85a2.08,2.08,0,0,1-.47.75,2.21,2.21,0,0,1-.69.5,2.16,2.16,0,0,1-.84.2,2,2,0,0,1-.87-.14l-.63-.23a2.37,2.37,0,0,1-.76-.47,2.22,2.22,0,0,1-.49-.7,2.07,2.07,0,0,1-.2-.83,2.24,2.24,0,0,1,.13-.88l.6-1.59a.3.3,0,0,1,.33-.22l1.66.17q.24,0,.15.24l-.76,2.05a.4.4,0,0,0,0,.32.4.4,0,0,0,.24.22l.13,0a.38.38,0,0,0,.33,0,.4.4,0,0,0,.22-.24l.82-2.19a1.6,1.6,0,0,0,.07-.41.73.73,0,0,0-.08-.37l-1.53-2.44a1.79,1.79,0,0,1-.19-.6,1.63,1.63,0,0,1,.13-.66l.74-2a2.08,2.08,0,0,1,.47-.75,2.25,2.25,0,0,1,1.53-.7,2,2,0,0,1,.87.14l.64.23A2.28,2.28,0,0,1,72.6,158.4Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M81.43,160.91a2.17,2.17,0,0,1,.58.63,2.21,2.21,0,0,1,.3.8,2,2,0,0,1,0,.88l-.16.66a.3.3,0,0,1-.29.26l-1.67,0c-.16,0-.22-.06-.19-.23l.27-1.12a.4.4,0,0,0,0-.32.41.41,0,0,0-.27-.19l-.13,0a.42.42,0,0,0-.33.05.46.46,0,0,0-.19.26l-.37,1.57a1.4,1.4,0,0,0,0,.35.94.94,0,0,0,.13.32l1.86,2.23a1.07,1.07,0,0,1,.24.57,4,4,0,0,1,0,.59l-.7,3a2.27,2.27,0,0,1-.38.8,2.17,2.17,0,0,1-.63.58,2.21,2.21,0,0,1-.8.3,2.18,2.18,0,0,1-.88,0l-.66-.16a2,2,0,0,1-.8-.37,2.16,2.16,0,0,1-.58-.63,2.42,2.42,0,0,1-.31-.8,2.23,2.23,0,0,1,0-.89l.39-1.66a.3.3,0,0,1,.3-.25l1.67,0c.16,0,.22.07.18.23l-.5,2.12a.4.4,0,0,0,0,.32.38.38,0,0,0,.27.19l.13,0a.47.47,0,0,0,.33,0,.44.44,0,0,0,.19-.27l.54-2.28a2,2,0,0,0,0-.41.75.75,0,0,0-.13-.36L77,165.35a1.67,1.67,0,0,1-.25-.58,1.4,1.4,0,0,1,0-.66l.49-2.07a2.14,2.14,0,0,1,.37-.81,2.48,2.48,0,0,1,.63-.58,2.41,2.41,0,0,1,.8-.3,2.23,2.23,0,0,1,.89,0l.65.15A2.27,2.27,0,0,1,81.43,160.91Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M97.43,173a2.29,2.29,0,0,1-.17.87,2.17,2.17,0,0,1-.48.71,2.06,2.06,0,0,1-.7.49,2.12,2.12,0,0,1-.87.18h-.67a2,2,0,0,1-.87-.17,2.19,2.19,0,0,1-.72-.47,2.22,2.22,0,0,1-.48-.71,2.11,2.11,0,0,1-.18-.86l-.05-8.2a2,2,0,0,1,.17-.87,2.15,2.15,0,0,1,.47-.71,2.34,2.34,0,0,1,.71-.48,2.29,2.29,0,0,1,.86-.19h.68a2.36,2.36,0,0,1,.87.17,2.17,2.17,0,0,1,.71.48,2.27,2.27,0,0,1,.67,1.57Zm-2.13-8.09a.41.41,0,0,0-.12-.3.44.44,0,0,0-.3-.12h-.15a.38.38,0,0,0-.3.13.41.41,0,0,0-.12.3l0,8a.43.43,0,0,0,.43.42h.14a.46.46,0,0,0,.3-.13.42.42,0,0,0,.13-.3Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M106.06,161.83l.24,1.56a.23.23,0,0,1-.21.29l-2,.3.39,2.53,1.74-.27c.16,0,.25,0,.28.21l.24,1.56c0,.16,0,.25-.21.28l-1.73.27.83,5.4c0,.16,0,.25-.21.28l-1.56.24c-.16,0-.25,0-.28-.21l-1.82-11.78a.22.22,0,0,1,.21-.28l3.8-.59A.23.23,0,0,1,106.06,161.83Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M121.42,165.47c0,.07,0,.13,0,.2a1.06,1.06,0,0,0,0,.19,9.11,9.11,0,0,0-.14.9c0,.28,0,.51,0,.72a4.61,4.61,0,0,0,0,.65l2.57-1a.14.14,0,0,1,.21.09l.59,1.58a.14.14,0,0,1-.1.2l-4.38,1.65c-.08,0-.15,0-.2-.1a4.2,4.2,0,0,1-.3-.6c-.08-.2-.15-.39-.2-.56a4.85,4.85,0,0,1-.14-.58,3,3,0,0,1-.11-.65q0-.31,0-.57a3.4,3.4,0,0,1,0-.57c.09-.63.19-1.19.29-1.68s.2-.92.3-1.29.21-.78.32-1.1a.72.72,0,0,0-.06-.37,3.14,3.14,0,0,1-.19-.5l-.74-2a.4.4,0,0,0-.22-.24.4.4,0,0,0-.32,0l-.14.05a.43.43,0,0,0-.24.22.42.42,0,0,0,0,.33l.41,1.09a.29.29,0,0,1-.1.38l-1.36,1c-.13.1-.22.07-.28-.09l-.64-1.71a2,2,0,0,1-.14-.87,2.11,2.11,0,0,1,.2-.83,2.4,2.4,0,0,1,.49-.7,2.26,2.26,0,0,1,.75-.47l.64-.24a2.18,2.18,0,0,1,.87-.14,2.3,2.3,0,0,1,.83.2,2.4,2.4,0,0,1,.7.49,2.26,2.26,0,0,1,.47.75l.38,1,.36,1a3.85,3.85,0,0,1,.25,1,3.22,3.22,0,0,1-.06,1.07c-.07.33-.15.66-.23,1S121.5,165.14,121.42,165.47Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M133.65,162.07a2.11,2.11,0,0,1,.26.84,2.25,2.25,0,0,1-.07.86,2.17,2.17,0,0,1-.38.76,2.2,2.2,0,0,1-.67.58l-.59.33a2.16,2.16,0,0,1-.85.26,2.2,2.2,0,0,1-.85-.07,2.09,2.09,0,0,1-.76-.38,2.2,2.2,0,0,1-.58-.67l-4-7.16a2,2,0,0,1-.26-.84,2.25,2.25,0,0,1,.07-.86,2.17,2.17,0,0,1,.38-.76,2.2,2.2,0,0,1,.67-.58l.59-.33a2.17,2.17,0,0,1,.85-.27,2.2,2.2,0,0,1,.85.08,2.09,2.09,0,0,1,.76.38,2.2,2.2,0,0,1,.58.67ZM127.88,156a.39.39,0,0,0-.25-.2.4.4,0,0,0-.32,0l-.13.07a.41.41,0,0,0-.2.25.43.43,0,0,0,0,.33l3.9,7a.45.45,0,0,0,.26.21.4.4,0,0,0,.32,0l.13-.07a.41.41,0,0,0,.2-.25.42.42,0,0,0,0-.33Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M135.13,148.67l7.31,9.42a.21.21,0,0,1,0,.34l-1.25,1a.22.22,0,0,1-.35,0l-5.46-7a3.81,3.81,0,0,1-.23.63,2.42,2.42,0,0,1-.37.58.18.18,0,0,1-.18.09.19.19,0,0,1-.18-.08l-.81-1.05a.22.22,0,0,1,0-.17.25.25,0,0,1,.06-.16,2.59,2.59,0,0,0,.32-.81,2.93,2.93,0,0,0,.08-.81,2.05,2.05,0,0,0-.28-.89.21.21,0,0,1,.06-.24l1-.8A.22.22,0,0,1,135.13,148.67Z'
                              fill={designs.stroke}
                            />
                            <path
                              d='M145.27,145.81a1.61,1.61,0,0,1-.09.62,1.63,1.63,0,0,1,.57-.17,1.26,1.26,0,0,1,.53.11,3.18,3.18,0,0,1,.62.45l2.29,2.15a2.25,2.25,0,0,1,.51.72,2.32,2.32,0,0,1,.19.84,2.25,2.25,0,0,1-.14.84,2.21,2.21,0,0,1-.46.76l-.46.49a2.15,2.15,0,0,1-.73.51,2.23,2.23,0,0,1-.83.19,2.26,2.26,0,0,1-.85-.13,2.11,2.11,0,0,1-.75-.47l-2.29-2.14a3.62,3.62,0,0,1-.5-.59l-.11-.26a.74.74,0,0,1,0-.26,1.63,1.63,0,0,1,.13-.58,1.46,1.46,0,0,1-.61.13,1.57,1.57,0,0,1-.75-.24l-.18-.13-.18-.16-1.46-1.37a2.19,2.19,0,0,1-.51-.73,2.23,2.23,0,0,1-.19-.83,2.29,2.29,0,0,1,.13-.85,2.11,2.11,0,0,1,.47-.75l.46-.49a2.14,2.14,0,0,1,.72-.52,2.5,2.5,0,0,1,.84-.19,2.47,2.47,0,0,1,.84.14,2.24,2.24,0,0,1,.76.47l1.46,1.37a1.37,1.37,0,0,1,.17.17,1.15,1.15,0,0,1,.14.17A1.43,1.43,0,0,1,145.27,145.81Zm-3.41-.87a.45.45,0,0,0-.31-.12.42.42,0,0,0-.29.14l-.1.1a.45.45,0,0,0-.12.31.42.42,0,0,0,.14.29l1.41,1.33a.64.64,0,0,0,.33.19l.15,0a.29.29,0,0,0,.18-.11l.13-.13a.37.37,0,0,0,.09-.19.92.92,0,0,0,0-.15c0-.08-.07-.19-.21-.32Zm3.68,3.46a1.24,1.24,0,0,0-.22-.18.42.42,0,0,0-.2-.1H145a.34.34,0,0,0-.19.13l-.12.13a.33.33,0,0,0-.12.2v.13a.42.42,0,0,0,.11.19,1.29,1.29,0,0,0,.19.21l2.16,2a.4.4,0,0,0,.31.11.41.41,0,0,0,.29-.13l.1-.1a.45.45,0,0,0,.12-.31.43.43,0,0,0-.14-.3Z'
                              fill={designs.stroke}
                            />
                          </g>

                          <g>
                            <g>
                              <path
                                d='M99.74,91.93l10.14-22a9.27,9.27,0,0,0-3.47-.07l-10.14,22A9.27,9.27,0,0,1,99.74,91.93Z'
                                fill={designs.stroke}
                              />
                              <path
                                d='M125.36,79.07a42.36,42.36,0,0,0-8.85-6.67l-10.14,22a42.36,42.36,0,0,1,8.85,6.67c11.6,11.06,17.75,7.84,17.75,7.84l10.14-22S137,90.13,125.36,79.07Zm-7.89,17.32-1.58-.92a.26.26,0,0,1-.11-.4L121,86.15a6.3,6.3,0,0,1-.78,0,3,3,0,0,1-.79-.15.2.2,0,0,1-.17-.15.24.24,0,0,1,0-.23l.78-1.33a.28.28,0,0,1,.15-.13.37.37,0,0,1,.21,0,3.05,3.05,0,0,0,1,0,3.34,3.34,0,0,0,.91-.27,2.25,2.25,0,0,0,.84-.69.24.24,0,0,1,.28,0l1.31.77a.27.27,0,0,1,.09.38l-6.93,12A.26.26,0,0,1,117.47,96.39Zm14.28-5.63a2.48,2.48,0,0,1-.33,1l-.62,1.07-.61,1a4.93,4.93,0,0,1-.75,1,3.81,3.81,0,0,1-1,.72l-1.09.54-1.1.52-.21.11-.2.11a9.13,9.13,0,0,0-.91.54c-.26.18-.49.34-.67.49s-.39.33-.57.5l2.76,1.6a.17.17,0,0,1,.07.26l-1,1.68a.17.17,0,0,1-.26.07l-4.7-2.72c-.08,0-.1-.13-.06-.26a5.55,5.55,0,0,1,.32-.7c.12-.22.24-.42.35-.59a5.15,5.15,0,0,1,.43-.56,4.57,4.57,0,0,1,.49-.57c.18-.17.34-.32.5-.45a5.31,5.31,0,0,1,.53-.38c.63-.38,1.21-.7,1.73-1s1-.5,1.37-.68.87-.39,1.23-.52a.84.84,0,0,0,.29-.34,5.51,5.51,0,0,1,.31-.53l1.22-2.1a.52.52,0,0,0,.05-.37.5.5,0,0,0-.23-.3l-.14-.08a.48.48,0,0,0-.68.18l-.68,1.17a.36.36,0,0,1-.42.19l-1.87-.52c-.18,0-.22-.14-.13-.31l1.07-1.83a2.41,2.41,0,0,1,.68-.77,2.62,2.62,0,0,1,.89-.43,2.57,2.57,0,0,1,1-.07,2.49,2.49,0,0,1,1,.33l.68.39a2.54,2.54,0,0,1,1.2,1.58A2.47,2.47,0,0,1,131.75,90.76Z'
                                fill={designs.stroke}
                              />
                            </g>
                            <g>
                              <path
                                d='M93.73,91.86l-10.14-22a9.27,9.27,0,0,0-3.47.07l10.14,22A9.27,9.27,0,0,1,93.73,91.86Z'
                                fill={designs.stroke}
                              />
                              <path
                                d='M68.41,85.05a.46.46,0,0,0-.3-.23.44.44,0,0,0-.37.05l-.57.33,2.07,3.58.57-.33a.5.5,0,0,0,.23-.3.51.51,0,0,0-.05-.38Z'
                                fill={designs.stroke}
                              />
                              <path
                                d='M64.64,79.07C53,90.13,46.89,86.92,46.89,86.92l10.14,22s6.15,3.22,17.75-7.84a42,42,0,0,1,8.85-6.67l-10.14-22A42.36,42.36,0,0,0,64.64,79.07Zm1.67,22.44-1.66.83a.25.25,0,0,1-.38-.13l-2.38-4.75L56,90.91a.22.22,0,0,1-.07-.19.19.19,0,0,1,.13-.16l1.95-1a.3.3,0,0,1,.42.11L61.45,94l.07,0-1.59-5a.31.31,0,0,1,.17-.41l1.95-1a.2.2,0,0,1,.21,0,.19.19,0,0,1,.1.16l1.7,8.68,2.38,4.74A.26.26,0,0,1,66.31,101.51Zm10.23-7.64-1.62.94a.42.42,0,0,1-.25,0,.51.51,0,0,1-.24-.1l-3.82-3.61,2.67,4.61-.12-.17a.24.24,0,0,1-.13.31l-1.59.92a.26.26,0,0,1-.39-.11l-6.93-12a.25.25,0,0,1,.11-.39l2.67-1.55a2.48,2.48,0,0,1,1-.33,2.73,2.73,0,0,1,1,.07,2.65,2.65,0,0,1,.89.44,2.41,2.41,0,0,1,.68.77l1.68,2.9A2.45,2.45,0,0,1,72.47,88a2.58,2.58,0,0,1-.42,1.32l4.52,4.16C76.7,93.66,76.69,93.78,76.54,93.87Z'
                                fill={designs.stroke}
                              />
                            </g>
                          </g>

                          <g>
                            <polygon
                              points='28.72 102.73 16.5 102.73 19.14 96.88 16.5 91.02 28.72 91.02 28.72 102.73'
                              fill={designs.fill}
                            />
                            <g>
                              <path
                                d='M36.76,95.12A58.24,58.24,0,1,0,95,36.87,58.31,58.31,0,0,0,36.76,95.12Zm115.33,0A57.09,57.09,0,1,1,95,38,57.15,57.15,0,0,1,152.09,95.12Z'
                                fill={designs.fill}
                              />
                              <polygon
                                points='161.28 91.02 173.5 91.02 170.87 96.88 173.5 102.73 161.28 102.73 161.28 91.02'
                                fill={designs.fill}
                              />
                              <path
                                d='M95,184.07A89.37,89.37,0,1,1,184.37,94.7,89.47,89.47,0,0,1,95,184.07ZM95,6.59A88.12,88.12,0,1,0,183.11,94.7,88.21,88.21,0,0,0,95,6.59Z'
                                fill={designs.fill}
                              />
                            </g>
                            <g>
                              <rect
                                x='86.4'
                                y='65.45'
                                width='2.89'
                                height='59.24'
                                transform='translate(-31.76 45.56) rotate(-24.78)'
                                fill={designs.fill}
                              />
                              <rect
                                x='72.62'
                                y='93.51'
                                width='59.24'
                                height='2.89'
                                transform='translate(-26.81 148) rotate(-65.23)'
                                fill={designs.fill}
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    )}
                  </svg>
                )}
              </g>
            </svg>
          </div>

          {type !== 'Mascots' && (
            <div className='cjd-preview-colors-wrapper cjd-multiple' data-color='pink'>
              <div className='cjd-color-selector' onClick={() => viewCbox('fill')}>
                <div className='cjd-color-pointer' style={{ backgroundColor: designs.fill }}></div>
                <span>Fill</span>
              </div>

              {type === 'Badges' && (
                <>
                  <div className='cjd-color-selector' onClick={() => viewCbox('stroke')}>
                    <div
                      className='cjd-color-pointer'
                      style={{ backgroundColor: designs.stroke }}
                    ></div>
                    <span>Stroke</span>
                  </div>

                  <div className='cjd-color-selector' onClick={() => viewCbox('border')}>
                    <div
                      className='cjd-color-pointer'
                      style={{ backgroundColor: designs.border }}
                    ></div>
                    <span>Border</span>
                  </div>
                </>
              )}

              {cPanel && (
                <div className='cjd-color-box'>
                  <label className='cjd-note'>
                    <span>Select {colPart} Color</span>
                    <div className='cjd-close-color-box' onClick={() => setCpanel(false)}>
                      {' '}
                      ×{' '}
                    </div>
                  </label>

                  <div className='cjd-colors-list'>
                    <div className='cjd-select-wrapper cjd-single'>
                      {BODY_COLORS.map((col, key) => (
                        <SelectBox
                          key={key}
                          type={colPart}
                          label={col}
                          current={designs[colPart]}
                          colors={true}
                          dispatch={(type, label) => updateColor(type, label)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  globals: state.globals,
  designs: state.designs,
  colors: state.colors,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateColor: (key, val) => dispatch(designColor(key, val)),
  updateName: (key, val, part, font, tab = 'symbol') =>
    dispatch(chooseName(key, val, part, font, tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Symbol);
